<template>
  <div>
    <div :class="$style.orders">
      <div>
        <Title text="Рассылки" position="right" />
      </div>
      <div :class="$style.orders__header">
        <date-picker v-model="date" range :lang="lang" placeholder="Дата" />
        <Input :class="$style.orders__input" placeholder="Поиск" v-model="filterValue" />
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.sortValue"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                v-on:click="sortOrders(headerColumn.sortValue)"
                :class="$style.table__header_column_icon"
              />
            </div>
          </div>
        </div>
        <div :class="$style.table__content">
          <template v-for="order in totalItems">
            <Newsletters-row :order="order" :key="order.id"/>
          </template>

          <div :class="$style.table__content_rowLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.orders__footer">
      <div>
        <pagination
          :pageCount="orderPageCount"
          :prevText="''"
          :click-handler="onNewslettersPageChange"
          :nextText="''"
          :containerClass="'pagination'"
          :page-class="'page'"
          :initial-page="this.$route.query.page - 1"
        />
      </div>
      <div :class="$style.orders__footer_button">
        <button @click="onNewsletterButtonClick">Создать рассылку</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Moment from 'moment'
//import orderBy from 'lodash/orderBy'
import orders from '@/api/orders'

import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'

import Title from '@/basic/Title'
import Input from '@/basic/Input'
import NewslettersRow from '@/components/NewslettersRow'

import paginationMixin from '@/mixins/pagination.mixin'

import 'vue2-datepicker/index.css'

export default {
  name: 'Newsletters',
  components: {
    NewslettersRow,
    Title,
    Input,
    DatePicker
  },
  data() {
    return {
      lang: ru,
      orders: [],
      date: [],
      filterValue: '',
      sortMethod: 'desc',
      initialPage: 1,
      headerColumns: [
        {
          id: 1,
          sortValue: 'id',
          title: 'id'
        },
        {
          id: 2,
          title: 'Дата и время отправки сообщения'
        },
        {
          id: 3,
          title: 'Группа(ы) рассылки'
        },
        {
          id: 4,
          title: 'Тема сообщения'
        },
        {
          id: 5,
          title: 'Текст сообщения'
        },

      ]
    }
  },
  mixins: [paginationMixin],

  async mounted() {
    const page = parseInt(this.$route.query.page)
    const res = await orders.getNewsletters(
      {
          pagination: {
            offset:page * 10 - 10,
            limit: 10
          },
          scan: {
            fields:
                ['id'],
            value: this.filterValue
            },
          order: {
            field: 'id',
            by:this.sortMethod
            }
          },
      )

    this.orders = res.data.result.rows
    this.setupOrderPagination(this.orders, res.data.result.count)
  },
  watch: {
    filterValue: {
      async handler() {
        const page = parseInt(this.$route.query.page)
        const dataFilter = {
          pagination: {
            offset:page * 10 - 10,
            limit: 10
          },
          scan: {
            fields:
              ['id'],
            value: this.filterValue
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        }
        if (this.startDate && this.endDate) {
          dataFilter.filter = {
            createdAt: {
              start: this.moment(this.startDate).format('YYYY-MM-DD'),
              end: this.moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
            }
          }
        }
        const res = await orders.getNewsletters(dataFilter)

        this.orders = res.data.result.rows
        this.setupOrderPagination(this.orders, res.data.result.count)
      }
    },
    date: {
      async handler() {
        if (
          !this.date.length ||
          (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
        ) {
          return this.orders
        }
        const page = parseInt(this.$route.query.page)
        const res = await orders.getNewsletters({
          pagination: {
            offset: page * 10 - 10,
            limit: 10
          },
          scan: {
            fields: ['id'],
            value: this.filterValue
          },
          order: {
            field: 'id',
            by: this.sortMethod
          },
          filter: {
            createdAt: {
              start: this.moment(this.startDate).format('YYYY-MM-DD'),
              end: this.moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
            }
          }
        })

        this.orders = res.data.result.rows
        this.setupOrderPagination(this.orders, res.data.result.count)
        //this.setupOrderPagination(this.totalOrders)
      }
    }
  },

  computed: {
    moment() {
      return Moment
    },
    startDate() {
      return this.date.length && this.moment(this.date[0]).format('YYYY-MM-DD LT')
    },
    endDate() {
      return this.date.length && this.moment(this.date[1]).format('YYYY-MM-DD LT')
    },
    filteredByDateOrders() {
      if (
        !this.date.length ||
        (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
      ) {
        return this.orders
      }
      return this.orders?.filter(
        (order) =>{

          return this.moment(order.datetime).isAfter(this.moment(this.startDate).format("YYYY-MM-DD") ) &&
            this.moment(order.datetime).isBefore(this.moment(this.endDate).add(1, 'days').format("YYYY-MM-DD"))
        }

      )
    },
    filteredOrders() {
      return this.filteredByDateOrders?.filter(
        (order) =>
          order.id.toString().indexOf(this.filterValue) >= 0 ||
          order.status.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order.teacher?.attributes?.lastName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order.teacher?.attributes?.firstName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          (order.teacher?.attributes?.middleName &&
            order.teacher?.attributes?.middleName
              .toLowerCase()
              .indexOf(this.filterValue.toLowerCase()) >= 0) ||
          order.student?.attributes?.lastName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order?.student?.attributes?.firstName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          (order.student?.attributes?.middleName &&
            order.student?.attributes?.middleName
              .toLowerCase()
              .indexOf(this.filterValue.toLowerCase()) >= 0)
      )
    },
    totalOrders: {
      get() {
        return this.filteredOrders
      },
      set(newtotalOrders){
        return newtotalOrders
      }
    }
  },
  methods: {
    ...mapMutations(['setOrderDetails']),

    setOrder(order) {
      this.setOrderDetails(order)
      this.$router.push(`/arbitrations/${order.id}`)
    },
    async sortOrders() {
      this.toggleSortMethod()
      const page = parseInt(this.$route.query.page)
      const res = await orders.getArbitrations(
        {
          pagination: {
            offset:page * 10 - 10,
            limit: 10
          },
          scan: {
            fields:
              ['studentFirstName', 'studentLastName', 'studentMiddleName', 'teacherFirstName', 'teacherLastName', 'teacherMiddleName','id'],
            value: this.filterValue
          },
          order: {
            field: 'id',
            by:this.sortMethod
          }
        },
      )

      this.orders = res.data.result.rows
      this.setupOrderPagination(this.totalOrders, res.data.result.count)
    },
    onNewsletterButtonClick(){
      this.$router.push(`/newsletters/new`)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.orders {
  &__header {
    display: flex;
    margin: 0 0 2rem;
  }

  &__input {
    width: 15rem;
    margin-right: 4rem;
    margin-left: 30px;
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }
      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 22.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        cursor: pointer;
        transition: all 0.3s ease;
        &_active {
          background: red;
          border-right: 1px solid $table-border;
        }

        &:hover {
          background: #e7e4e4;

          opacity: 0.8;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 9.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 22.5%;
        }

        &:last-child {
          border: none;
        }
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 22.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    &_button {
      width: 200px;
      margin-top: 30px;
      button {
        padding: 0.75rem 1.5rem;
        background: #f1e6e6;
        border: 1px solid #c4c4c4;
        border-radius: 1rem;
        font-size: 1.125rem;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
