<template>
  <div
    :class="[
      $style.table__content_row

    ]"
    id="table__content_row"
  >
    <div :class="$style.table__content_column">
      {{ order.id }}
   </div>
    <div :class="$style.table__content_column">
      {{ order.createdAt ? moment(order.createdAt).format('YYYY-MM-DD  LT') : 'Не заполнено' }}
    </div>
    <div :class="$style.table__content_column" >
      {{ order && order.recipients ? recipients : 'Не заполнено' }}
    </div>
    <div :class="$style.table__content_column" >
      {{order.title}}
    </div>

    <div :class="$style.table__content_column">
      {{order.message}}
    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Moment from 'moment'

export default {
  name: 'NewslettersRow',
  props: ['order'],
  computed: {
    moment() {
      return Moment
    },
    recipients(){
      return this.order.recipients.map((el)=>{
        return el.id
      }).join(',')
    }

  },
  methods: {
    ...mapMutations(['setOrderDetails']),
  }
}
</script>

<style module lang="scss">
@import '@/assets/styles/colors.scss';

.table {
  &__content {
    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //height: 2.5rem;
      border-bottom: 1px solid $table-border;
      cursor: pointer;
      transition: all 0.3s ease;
      &_active {
        background: red;
        border-right: 1px solid $table-border;
      }

      &:hover {
        background: #e7e4e4;

        opacity: 0.8;
      }
    }
    &_column {
      display: flex;
      align-items: center;
      padding: 0.7rem 1rem;
      font-size: 0.875rem;
      font-weight: 500;

      &:first-child {
        width: 9.5%;
        justify-content: center;
      }

      &:nth-child(n + 2) {
        width: 18.5%;
      }

      &:last-child {
        border: none;
      }
    }

    &_rowLine {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }

    &_line {
      border-right: 1px solid $table-border;

      &:first-child {
        width: 7.5%;
      }

      &:nth-child(n + 2) {
        width: 18.5%;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
